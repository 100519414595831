import React, { useContext } from 'react';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as Constants from '../api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function createData(id, property, value) {
  return { id, property, value };
}

const rows = [
  createData(0, 'Command Echo', 'N/A',),
  createData(1, 'Software Version', 'N/A',),
  createData(2, 'BLE MAC Address', 'N/A',),
  createData(3, 'Wi-Fi RSSI', 'N/A',),
  createData(4, 'Up time: Total', 'N/A',),
  createData(5, 'Up time: Wi-Fi', 'N/A',),
  createData(6, 'Up time: BLE', 'N/A',),
  createData(7, 'Up time: TCP', 'N/A',),
  createData(8, 'Wi-Fi SSID', 'N/A',),
  createData(9, 'BSSID enable', 'N/A',),
  createData(10, 'BSSID MAC Address', 'N/A',),
  createData(11, 'Wi-Fi Fail Counter', 'N/A',),
  createData(12, 'TCP Fail Counter', 'N/A',),
  createData(13, 'Server RF Ping Fail Counter', 'N/A',),
  createData(14, 'Wi-Fi MAC Address', 'N/A',),
  createData(15, 'MQTT Server Address', 'N/A',),
  createData(16, 'MQTT Status', 'N/A',)
];

export default function SsigSnSsigRF() {
  const state = useContext(MainContext).state;
  const sn = state.snSsigRf?.split(',') ?? [];
  let deviceId = state.selectedDevice;

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClickListener = () => {
    sendCommand('SN-SSIG-RF', '');
  }
  if (sn.length > 2) {
    rows[0].value = sn[0];
    rows[1].value = sn[1];
    rows[2].value = sn[2];
    rows[3].value = sn[3];
    rows[4].value = sn[4];
    rows[5].value = sn[5];
    rows[6].value = sn[6];
    rows[7].value = sn[7];
    rows[8].value = sn[8];
    rows[9].value = sn[9];
    rows[10].value = sn[10];
    rows[11].value = sn[11];
    rows[12].value = sn[12];
    rows[13].value = sn[13];
    rows[14].value = sn[14];
    rows[15].value = sn[15];
    rows[16].value = sn[16];
  } else {
    rows[0].value = 'N/A';
    rows[1].value = 'N/A';
    rows[2].value = 'N/A';
    rows[3].value = 'N/A';;
    rows[4].value = 'N/A';
    rows[5].value = 'N/A';
    rows[6].value = 'N/A';
    rows[7].value = 'N/A';
    rows[8].value = 'N/A';
    rows[9].value = 'N/A';
    rows[10].value = 'N/A';
    rows[11].value = 'N/A';
    rows[12].value = 'N/A';
    rows[13].value = 'N/A';
    rows[14].value = 'N/A';
    rows[15].value = 'N/A';
    rows[16].value = 'N/A';
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>SN-SSIG-RF Data</Title>
      <Table size="small" sx={{ mb: 3 }} >
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="outlined" onClick={() => onClickListener()}>Update</Button>
    </React.Fragment>
  );
}