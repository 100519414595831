import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Copyright from './copyright.component/copyright'
import HistoryHourMeter from './history.components/HistoryHourMeter';
import HistoryStartDate from './history.components/HistoryStartDate';
import HistoryVoltageLogs from './history.components/HistoryVoltageLogs';
import HistoryFaultLogs from './history.components/HistoryFaultLogs';
import SsigSnSsigRF from './ssig.components/SsigSnSsigRF';
import SsigSnSsigR2 from './ssig.components/SsigSnSsigR2';
import { MainRfContext } from '../context/MainRfContext';
import { MainContext } from '../context/MainContext';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function SSIGContent() {
  const state = useContext(MainRfContext);
  if ((state.selectedDevice === 0)) {
    return (<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert variant="filled" severity="error">
        No spa associated with this account
      </Alert>
    </Stack>
  </Container>)
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <MainContext.Provider value={{ state }}>
          <Grid container spacing={3}>
            {/* SN-SSIG-RF */}  
            <Grid item xs={22} md={8} lg={6}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
                <SsigSnSsigRF />
              </Paper>
            </Grid>
            {/* SN-SSIG-R2 */}  
            <Grid item xs={22} md={8} lg={6}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
                <SsigSnSsigR2 />
              </Paper>
            </Grid>
          </Grid>
        </MainContext.Provider>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    );
  }
}

export default function SSIG() {
  return <SSIGContent />;
}