import React, { useContext } from 'react';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as Constants from '../api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function createData(id, property, value) {
  return { id, property, value };
}

const rows = [
  createData(0, 'Command Echo', 'N/A',),
  createData(1, 'GPIO Input 1 - Status (SG1 input, S200)', 'N/A',),
  createData(2, 'GPIO Input 2 - Status (SG2 input, S201 or S202)', 'N/A',),
  createData(3, 'GPIO Output 1 - Status (Spa heating output, S203)', 'N/A',),
  createData(4, 'Client 1 - Connected', 'N/A',),
  createData(5, 'Client 1 - IP Address', 'N/A',),
  createData(6, 'Client 1 - Mac Address', 'N/A',),
  createData(7, 'Client 1 - Wi-Fi RSSI', 'N/A',),
  createData(8, 'Client 1 - Type', 'N/A',),
  createData(9, 'Client 1 - Heat Signal', 'N/A',),
  createData(10, 'Client 2 - Connected', 'N/A',),
  createData(11, 'Client 2 - IP Address', 'N/A',),
  createData(13, 'Client 2 - Wi-Fi RSSI', 'N/A',),
  createData(12, 'Client 2 - Mac Address', 'N/A',),
  createData(14, 'Client 2 - Type', 'N/A',),
  createData(15, 'Client 2 - Heat Signal', 'N/A',),
  createData(16, 'Client 3 - Connected', 'N/A',),
  createData(17, 'Client 3 - IP Address', 'N/A',),
  createData(18, 'Client 3 - Mac Address', 'N/A',),
  createData(19, 'Client 3 - Wi-Fi RSSI', 'N/A',),
  createData(20, 'Client 3 - Type', 'N/A',),
  createData(21, 'Client 3 - Heat Signal', 'N/A',)
];

export default function SsigSnSsigR2() {
  const state = useContext(MainContext).state;
  const snSsigR2 = state.snSsigR2?.split(',') ?? [];
  let deviceId = state.selectedDevice;

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClickListenerUpdate = () => {
    sendCommand('SN-SSIG-R2', '');
  }

  if (snSsigR2.length > 2) {
    rows[0].value = snSsigR2[0];
    rows[1].value = snSsigR2[1];
    rows[2].value = snSsigR2[2];
    rows[3].value = snSsigR2[3];
    rows[4].value = snSsigR2[4];
    rows[5].value = snSsigR2[5];
    rows[6].value = snSsigR2[6];
    rows[7].value = snSsigR2[7];
    rows[8].value = snSsigR2[8];
    rows[9].value = snSsigR2[9];
    rows[10].value = snSsigR2[10];
    rows[11].value = snSsigR2[11];
    rows[12].value = snSsigR2[12];
    rows[13].value = snSsigR2[13];
    rows[14].value = snSsigR2[14];
    rows[15].value = snSsigR2[15];
    rows[16].value = snSsigR2[16];
    rows[17].value = snSsigR2[17];
    rows[18].value = snSsigR2[18];
    rows[19].value = snSsigR2[19];
    rows[20].value = snSsigR2[20];
    rows[21].value = snSsigR2[21];
  } else {
    rows[0].value = 'N/A';
    rows[1].value = 'N/A';
    rows[2].value = 'N/A';
    rows[3].value = 'N/A';
    rows[4].value = 'N/A';
    rows[5].value = 'N/A';
    rows[6].value = 'N/A';
    rows[7].value = 'N/A';
    rows[8].value = 'N/A';
    rows[9].value = 'N/A';
    rows[10].value = 'N/A';
    rows[11].value = 'N/A';
    rows[12].value = 'N/A';
    rows[13].value = 'N/A';
    rows[14].value = 'N/A';
    rows[15].value = 'N/A';
    rows[16].value = 'N/A';
    rows[17].value = 'N/A';
    rows[18].value = 'N/A';
    rows[19].value = 'N/A';
    rows[20].value = 'N/A';
    rows[21].value = 'N/A';
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>SN-SSIG-R2 Data</Title>
      <Table size="small" sx={{ mb: 3 }} >
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="outlined" onClick={() => onClickListenerUpdate()}>Update</Button>
    </React.Fragment>
  );
}